import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { DateTime } from "luxon"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { FaRegCalendarAlt } from "react-icons/fa"

import Layout from "../components/layout.component"
import Seo from "../components/seo.component"

import spinner from "../images/spinner.png"

import "./custom-page.styles.scss"

const contentful = require("contentful")
const contentfulClient = contentful.createClient({
  space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
  accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
})

export const query = graphql`
  query {
    contentfulCustomPage(slug: { eq: "winter-flute-workshop" }) {
      title
      bannerImage {
        file {
          url
        }
      }
    }
  }
`

const WinterFluteWorkshopPage = ({ data }) => {
  const [pageContent, setPageContent] = useState(null)

  useEffect(() => {
    contentfulClient
      .getEntries({
        content_type: "workshopPage",
        "fields.slug": "winter-flute-workshop",
      })
      .then(response => {
        setPageContent(response.items[0])
      })
      .catch(console.error)
  }, [])

  return (
    <Layout className="custom-page">
      <Seo
        title="Winter Flute Workshop"
        description="The Parasky Flute Studios Winter Flute Workshop features local/regional guest artists in a traditional master class and performance setting."  
      />
      <section
        className="custom-page__hero hero is-medium is-dark"
        style={{
          backgroundImage: data.contentfulCustomPage.bannerImage ? "url(" + data.contentfulCustomPage.bannerImage.file.url + ")" : ""
        }}
      >
        <div className="container">
          <div className="hero-body is-flex-direction-column is-justify-content-center has-text-shadow">
            <h1 className="title is-size-2 is-size-1-desktop has-text-white is-uppercase has-text-centered">
              {data.contentfulCustomPage.title}
            </h1>
          </div>
        </div>
      </section>
      <section className="custom-page__main">
        {pageContent ? (
          <>
            {pageContent.fields.introVideo ? (
              <section className="custom-page__intro">
                <div className="container">
                  <div className="columns">
                    <div className="intro__media column is-one-third">
                      <div className="video-container">
                        <iframe
                          src={pageContent.fields.introVideo}
                          title="2020 Parasky Flute Studios Summer Flute Workshop Faculty Recital"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                    <div className="intro__body column is-two-thirds">
                      {pageContent.fields.intro && (
                        <div className="content is-size-6">
                          {pageContent.fields.date && (
                            <h2>
                              <FaRegCalendarAlt className="mr-4" />
                              {DateTime.fromISO(pageContent.fields.date).toFormat("dd LLLL yyyy")}
                            </h2>
                          )}
                          <div>
                            {documentToReactComponents(
                              pageContent.fields.intro
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              <section className="content custom-page__intro--no-vid is-size-6">
                <div className="container">
                  {pageContent.fields.intro &&
                    documentToReactComponents(pageContent.fields.intro)}
                </div>
              </section>
            )}

            {pageContent.fields.section1Body && (
              <section
                id="section1"
                className="custom-page__section custom-page__section--alt columns"
              >
                <div className="container">
                  {pageContent.fields.section1Title && (
                    <div className="content">
                      <h2 className="pb-2">
                        {pageContent.fields.section1Title}
                      </h2>
                    </div>
                  )}
                  <div className="columns is-flex-direction-row-reverse">
                    {pageContent.fields.section1Image && (
                      <div className="section__media column is-one-third">
                        <figure className="image">
                          <img
                            src={
                              pageContent.fields.section1Image.fields.file.url
                            }
                            alt={pageContent.fields.section1Image.fields.title}
                          />
                        </figure>
                      </div>
                    )}
                    <div
                      className={`section__body column ${
                        pageContent.fields.section1Image ? "is-two-thirds" : ""
                      }`}
                    >
                      <div className="content is-size-6">
                        {pageContent.fields.section1Body &&
                          documentToReactComponents(
                            pageContent.fields.section1Body
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {pageContent.fields.section2Body && (
              <section id="section2" className="custom-page__section columns">
                <div className="container">
                  {pageContent.fields.section2Title && (
                    <div className="content">
                      <h2 className="pb-2">
                        {pageContent.fields.section2Title}
                      </h2>
                    </div>
                  )}
                  <div className="columns">
                    {pageContent.fields.section2Image && (
                      <div className="section__media column is-one-third">
                        <figure className="image">
                          <img
                            src={
                              pageContent.fields.section2Image.fields.file.url
                            }
                            alt={pageContent.fields.section2Image.fields.title}
                          />
                        </figure>
                      </div>
                    )}
                    <div
                      className={`section__body column ${
                        pageContent.fields.section2Image ? "is-two-thirds" : ""
                      }`}
                    >
                      <div className="content is-size-6">
                        {pageContent.fields.section2Body &&
                          documentToReactComponents(
                            pageContent.fields.section2Body
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {pageContent.fields.section3Body && (
              <section
                id="section3"
                className="custom-page__section custom-page__section--alt columns"
              >
                <div className="container">
                  {pageContent.fields.section3Title && (
                    <div className="content">
                      <h2 className="pb-2">
                        {pageContent.fields.section3Title}
                      </h2>
                    </div>
                  )}
                  <div className="columns is-flex-direction-row-reverse">
                    {pageContent.fields.section3Image && (
                      <div className="section__media column is-one-third">
                        <figure className="image">
                          <img
                            src={
                              pageContent.fields.section3Image.fields.file.url
                            }
                            alt={pageContent.fields.section3Image.fields.title}
                          />
                        </figure>
                      </div>
                    )}
                    <div
                      className={`section__body column ${
                        pageContent.fields.section3Image ? "is-two-thirds" : ""
                      }`}
                    >
                      <div className="content is-size-6">
                        {pageContent.fields.section3Body &&
                          documentToReactComponents(
                            pageContent.fields.section3Body
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {pageContent.fields.section4Body && (
              <section id="section4" className="custom-page__section columns">
                <div className="container">
                  {pageContent.fields.section4Title && (
                    <div className="content">
                      <h2 className="pb-2">
                        {pageContent.fields.section4Title}
                      </h2>
                    </div>
                  )}
                  <div className="columns">
                    {pageContent.fields.section4Image && (
                      <div className="section__media column is-one-third">
                        <figure className="image">
                          <img
                            src={
                              pageContent.fields.section4Image.fields.file.url
                            }
                            alt={pageContent.fields.section4Image.fields.title}
                          />
                        </figure>
                      </div>
                    )}
                    <div
                      className={`section__body column ${
                        pageContent.fields.section4Image ? "is-two-thirds" : ""
                      }`}
                    >
                      <div className="content is-size-6">
                        {pageContent.fields.section4Body &&
                          documentToReactComponents(
                            pageContent.fields.section4Body
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {pageContent.fields.section5Body && (
              <section
                id="section5"
                className="custom-page__section custom-page__section--alt columns"
              >
                <div className="container">
                  {pageContent.fields.section5Title && (
                    <div className="content">
                      <h2 className="pb-2">
                        {pageContent.fields.section5Title}
                      </h2>
                    </div>
                  )}
                  <div className="columns is-flex-direction-row-reverse">
                    {pageContent.fields.section5Image && (
                      <div className="section__media column is-one-third">
                        <figure className="image">
                          <img
                            src={
                              pageContent.fields.section5Image.fields.file.url
                            }
                            alt={pageContent.fields.section5Image.fields.title}
                          />
                        </figure>
                      </div>
                    )}
                    <div
                      className={`section__body column ${
                        pageContent.fields.section5Image ? "is-two-thirds" : ""
                      }`}
                    >
                      <div className="content is-size-6">
                        {pageContent.fields.section5Body &&
                          documentToReactComponents(
                            pageContent.fields.section5Body
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {pageContent.fields.section6Body && (
              <section id="section6" className="custom-page__section columns">
                <div className="container">
                  {pageContent.fields.section6Title && (
                    <div className="content">
                      <h2 className="pb-2">
                        {pageContent.fields.section6Title}
                      </h2>
                    </div>
                  )}
                  <div className="columns">
                    {pageContent.fields.section6Image && (
                      <div className="section__media column is-one-third">
                        <figure className="image">
                          <img
                            src={
                              pageContent.fields.section6Image.fields.file.url
                            }
                            alt={pageContent.fields.section6Image.fields.title}
                          />
                        </figure>
                      </div>
                    )}
                    <div
                      className={`section__body column ${
                        pageContent.fields.section6Image ? "is-two-thirds" : ""
                      }`}
                    >
                      <div className="content is-size-6">
                        {pageContent.fields.section6Body &&
                          documentToReactComponents(
                            pageContent.fields.section6Body
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </>
        ) : (
          <div className="custom-page__spinner">
            <img src={spinner} alt="Loading" />
          </div>
        )}
      </section>
    </Layout>
  )
}

export default WinterFluteWorkshopPage
